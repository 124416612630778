import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import dateFormat from 'dateformat';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import SEO from '../components/SEO';
import Header from '../components/Header';
import { commonHeaderColor } from '../util';
import RichText from '../components/RichText';
import ContentRow from '../components/ContentRow';


const langBases = [
  {
    language: 'en',
    url: 'blog'
  },
  {
    language: 'fr',
    url: 'blog'
  }
];

const BlogPost = ({ data, pageContext }) => {
  const { t } = useTranslation();

  const { blogIndex, itemsPerPage } = pageContext;
  const { alternateLanguagePages } = data;

  if (!data.contentfulBlog) {
    return null;
  }

  const blogPost = data.contentfulBlog;

  const blogPage = Math.floor(blogIndex / itemsPerPage) + 1;
  const hasImage = blogPost.featuredImage && blogPost.featuredImage.fluid;
  const blogUrl = `/blog/${blogPost.slug}/`;

  return (
    <>
      <SEO
        title={blogPost.metaTitle}
        description={blogPost.metaDescription}
        alternateLanguagePages={alternateLanguagePages}
        alternateLanguageBases={langBases}
      />
      <Header backgroundColor={commonHeaderColor} />
      <ContentRow backgroundColor={commonHeaderColor} padV>
        <div className="grid c-2">
          <div>
            {
              hasImage &&
              <Img fluid={blogPost.featuredImage.fluid} alt={blogPost.featuredImage.title} />
            }
          </div>
          <div>
            <div className="contentBox textAlignCenter">
              <h1>{blogPost.title}</h1>
            </div>
          </div>
        </div>
      </ContentRow>
      <div className="pageWidth">
        <p className="breadcrumbs">
          <Link to="/blog/">{t('blogNews')}</Link>
          {blogPage !== 1 && (
            <>
              <span>&gt;</span>
              <Link to={`/blog/${blogPage}/`}>{t('page')} {blogPage}</Link>
            </>
          )}
          <span>&gt;</span>
          <Link to={blogUrl}>{blogPost.title}</Link>
        </p>
        <div className="blogPost">
          <p className="time">{dateFormat(blogPost.createdAt, t('dateTimeFormat'))}</p>
          <div className="markdown">
            <RichText json={blogPost.content.json} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query ($slug: String!, $key: String!, $language: String!) {
    img1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "blog/getneosurf-blog-news-hero.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulBlog(slug: {eq: $slug}, language: {code: {eq: $language}}) {
      createdAt
      title
      slug
      content {
        json
      }
      featuredImage {
        title
        fluid(
          maxWidth: 912
        ) {
          ...GatsbyContentfulFluid
        }
      }
      metaDescription
      metaTitle
    }
    alternateLanguagePages: allContentfulBlog(filter: {key: {eq: $key}}) {
      edges {
        node {
          url: slug
          language {
            code
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
